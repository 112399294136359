import React, { useCallback } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";

import Logout from "@mui/icons-material/Logout";
import { NavLink } from "react-router-dom";
import { ListItemButton, ListItemText } from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Cookies from "js-cookie";

export default function ProfileMenu({ open, anchorEl, handleClose }) {
  const username = Cookies.get("username");
  const role = Cookies.get("role");

  const handleLogout = () => {
    Cookies.remove("Token");
    Cookies.remove("role");
    Cookies.remove("userid");
    Cookies.remove("username");
    Cookies.remove("image");
    Cookies.remove("checkin");
    Cookies.remove("checkout");
    window.location.pathname = "/login";
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <ListItemText primary={username} secondary={role} sx={{textAlign:"right",paddingRight:"1rem"}}/>
        <Divider />

        <MenuItem onClick={handleClose} sx={{ p: 0 }}>
          <NavLink
            to={"/profile"}
            style={{
              color: "#333",
              textDecoration: "none",
              width: "100%",
              padding: "8px 16px",
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <ListItemIcon>
              <AccountBoxIcon fontSize="small" />
            </ListItemIcon>
            Admin Profile
          </NavLink>
        </MenuItem>
       
        <MenuItem onClick={handleClose} sx={{ p: 0 }}>
          <NavLink
            to={"/logs"}
            style={{
              color: "#333",
              textDecoration: "none",
              width: "100%",
              padding: "8px 16px",
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <ListItemIcon>
              <AccountBoxIcon fontSize="small" />
            </ListItemIcon>
            Logs
          </NavLink>
        </MenuItem>

        <MenuItem onClick={handleClose} sx={{ p: 0 }}>
          <NavLink
            onClick={handleLogout}
            style={{
              textDecoration: "none",
              width: "100%",
              padding: "8px 16px",
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </NavLink>
        </MenuItem>
      </Menu>
    </>
  );
}

// export function NotificationList({ BellListopen, anchorEl, handleCloseBell }) {
//   const items = [
//     { primary: "Booking", secondary: "Jan 9, 2014", icon: <ImageIcon /> },
//     { primary: "Transaction", secondary: "Jan 7, 2014", icon: <WorkIcon /> },
//     { primary: "Order", secondary: "July 20, 2014", icon: <BeachAccessIcon /> },
//   ];

//   return (
//     <>
//       <Menu
//         anchorEl={anchorEl}
//         id="account-menu"
//         open={BellListopen}
//         onClose={handleCloseBell}
//         onClick={handleCloseBell}
//         PaperProps={{
//           elevation: 0,
//           sx: {
//             marginLeft: "-80px",
//             overflow: "visible",
//             filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
//             mt: 1.5,
//             "& .MuiAvatar-root": {
//               width: 32,
//               height: 32,
//               ml: -0.5,
//               mr: -10,
//             },
//             "&::before": {
//               content: '""',
//               display: "block",
//               position: "absolute",
//               top: 0,
//               right: 14,
//               width: 10,
//               height: 10,
//               bgcolor: "background.paper",
//               transform: "translateY(-50%) rotate(45deg)",
//               zIndex: 0,
//             },
//           },
//         }}
//         transformOrigin={{ horizontal: "left", vertical: "top" }}
//         anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
//       >
//         {/* <ListItemButton>
//           <ListItemIcon>
//           👋
//           </ListItemIcon>
//           <ListItemText primary={"Demo User"} secondary={"Manager"}/>
//       </ListItemButton>
//       <Divider /> */}

//         {items.map((item, index) => (
//           <MenuItem key={index} sx={{ pl: 4, pr: 4 }}>
//             <ListItemAvatar>
//               <Avatar>{item.icon}</Avatar>
//             </ListItemAvatar>
//             <ListItemText primary={item.primary} secondary={item.secondary} />
//           </MenuItem>
//         ))}
//       </Menu>
//     </>

//     // <Menu
//     //   anchorEl={anchorEl}
//     //   open={Boolean(BellListopen)}
//     //   onClose={handleCloseBell}

//     // >
//     //  <Box sx={{marginRight:"70px",border:"2px solid red"}}>
//     //  {items.map((item, index) => (
//     //     <MenuItem key={index}  sx={{marginRight:"70px",background:"transparent"}}>
//     //       <ListItemAvatar>
//     //         <Avatar>
//     //           {item.icon}
//     //         </Avatar>
//     //       </ListItemAvatar>
//     //       <ListItemText primary={item.primary} secondary={item.secondary} />
//     //     </MenuItem>
//     //   ))}
//     //  </Box>
//     // </Menu>
//   );
// }
