import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ErrorAlert } from "../../../Model/Alert/SuccessAlert";
const Reservation_BaseUrl = process.env.REACT_APP_API_URL + "/booking/by-room";

//%%%%%%%%%% GET USER API %%%%%%%%%%%%
export const Get_ReservationApi_BYID = createAsyncThunk(
  "Get_ReservationApi_BYID",
  async ({ Token, id }) => {
    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const response = await axios.get(`${Reservation_BaseUrl}/${id}`, {
        headers,
      });
      if (response.data.status === false) {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('error', error)
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

const ReservationSlice = createSlice({
  name: "ReservationSlice",
  initialState: {
    Room_ResevationData: [],
    // SpecificGuestdata:{},
    iserror: null,
    isLoading: null,
    message: "",
    status: null,
  },

  extraReducers: (builder) => {
    // //%%%%%%%%%%%%%%%%%%% VIEW API HANDLE %%%%%%%%%%%%%%%%%%%%%
    builder.addCase(Get_ReservationApi_BYID.rejected, (state, action) => {
      state.iserror = "Client side Error";
      state.message = action.payload.message;
      state.status = action.payload.status;
    });
    builder.addCase(Get_ReservationApi_BYID.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(Get_ReservationApi_BYID.fulfilled, (state, action) => {
      state.isLoading = false;
      state.Room_ResevationData = action.payload.status
        ? action.payload.result
        : [];
      state.message = action.payload.message;
      state.status = action.payload.status;
    });
  },
});

// export default ReservationSlice.reducer

export default ReservationSlice.reducer;
