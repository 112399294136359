import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import Cookies from "js-cookie";
import Layout from "./Layout";
import Loader from "./Components/Admin/Include/Loader";
import GuestCard from "./Components/Admin/Include/GuestCard";
import BookingRoomServiceDetail from "./Components/Admin/Other/Guest/BookingRoomServiceDetail";

//LOGIN IMPORTS
const Login = lazy(() => import("./Components/Admin/security/Login"));
const ForgotPassword = lazy(() =>
  import("./Components/Admin/security/ForgotPassword")
);
const OtpPage = lazy(() => import("./Components/Admin/security/OtpPage"));
const ResetPassword = lazy(() =>
  import("./Components/Admin/security/ResetPassword")
);

//DASHBOARD IMPORTS
const DashBoard = lazy(() => import("./Components/Admin/Dashboard/Dasboard"));
const AdminProfile = lazy(() =>
  import("./Components/Admin/AdminProfile/Profilepage")
);

//GUEST IMPORT
const ViewAllGuest = lazy(() =>
  import("./Components/Admin/Other/Guest/ViewAllGuest")
);
const AddGuest = lazy(() => import("./Components/Admin/Other/Guest/AddGuest"));
const EditGuest = lazy(() =>
  import("./Components/Admin/Other/Guest/EditGuest")
);
//BOOKING IMPORT
const Bookingrecord = lazy(() =>
  import("./Components/Admin/Other/BookingRecord/BookingRecord")
);
const Reservation = lazy(() =>
  import("./Components/Admin/Include/Reservation")
);
//ROOM IMPORT
const ViewAllRooms = lazy(() =>
  import("./Components/Admin/Other/Rooms/ViewAllRooms")
);
const AddRooms = lazy(() => import("./Components/Admin/Other/Rooms/AddRooms"));
const EditRooms = lazy(() =>
  import("./Components/Admin/Other/Rooms/EditRooms")
);
//ROOM SERVICE IMPORT
const ViewRoomService = lazy(() =>
  import("./Components/Admin/Other/Room_Service/ViewService")
);
const AddService = lazy(() =>
  import("./Components/Admin/Other/Room_Service/AddService")
);
const EditService = lazy(() =>
  import("./Components/Admin/Other/Room_Service/EditService")
);
//FOOD IMPORT
const Food = lazy(() => import("./Components/Admin/Other/Service_Item/Food"));
//CATEGORY IMPORT
const Category = lazy(() =>
  import("./Components/Admin/Other/Service_Item/Category")
);
//BILLING IMPORT
const BillingTabPanel = lazy(() =>
  import("./Components/Admin/Other/Billing/BillingTab")
);
const RoomInvoice = lazy(() =>
  import("./Components/Admin/Other/Billing/RoomInvoice")
);
const OrderInvoice = lazy(() =>
  import("./Components/Admin/Other/Billing/OrderInvoice")
);
const BothInvoice = lazy(() =>
  import("./Components/Admin/Other/Billing/BothInvoice")
);
const Reports = lazy(() => import("./Components/Admin/Other/Reports/Reports"));
//EXPENSE IMPORT
const Expense = lazy(() => import("./Components/Admin/Other/Expense/Expense"));
//COMPANY IMPORT
const Company = lazy(() => import("./Components/Admin/Other/Company/Company"));
const LogPage = lazy(() => import("./Components/Admin/LogPage/LogPage"));
const NotFound = lazy(() => import("./Components/Admin/Include/NotFound"));

const token = Cookies.get("Token");
const tempToken = Cookies.get("temp_token");

const ProtectedRoute = ({ element }) => {
  return token ? (
    <Suspense fallback={<Loader />}>{element}</Suspense>
  ) : (
    <Navigate to="/login" />
  );
};

const ProtectedRouteForPasswordReset = ({ element }) => {
  return tempToken ? (
    <Suspense fallback={<Loader />}>{element}</Suspense>
  ) : (
    <Navigate to="/reset" />
  );
};

const App = () => {
  return (
    <Box sx={{ background: "#FAFAFA", height: "100vh" }}>
      <Routes>
        {/* Login Route */}
        <Route
          path="/login"
          element={
            token ? (
              <Navigate to="/" />
            ) : (
              <Suspense fallback={<Loader />}>
                <Login />
              </Suspense>
            )
          }
        />
        <Route
          path="/reset"
          element={
            token ? (
              <Navigate to="/" />
            ) : (
              <Suspense fallback={<Loader />}>
                <ForgotPassword />
              </Suspense>
            )
          }
        />
        <Route
          path="/otp"
          element={
            token ? (
              <Navigate to="/" />
            ) : (
              <ProtectedRouteForPasswordReset element={<OtpPage />} />
            )
          }
        />
        <Route
          path="/confirm"
          element={
            token ? (
              <Navigate to="/" />
            ) : (
              <ProtectedRouteForPasswordReset element={<ResetPassword />} />
            )
          }
        />

        {/* Dashboard Route */}
        <Route
          path="/"
          element={
            <ProtectedRoute element={<Layout ChildComponent={DashBoard} />} />
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute
              element={<Layout ChildComponent={AdminProfile} />}
            />
          }
        />

        {/* Guest Route */}
        <Route
          path="/guest"
          element={
            <ProtectedRoute
              element={<Layout ChildComponent={ViewAllGuest} />}
            />
          }
        />
        <Route
          path="/guest-add"
          element={
            <ProtectedRoute element={<Layout ChildComponent={AddGuest} />} />
          }
        />
        <Route
          path="/guest-Edit/:id"
          element={
            <ProtectedRoute element={<Layout ChildComponent={EditGuest} />} />
          }
        />

        {/* Booking Route */}
        <Route
          path="/booking-record"
          element={
            <ProtectedRoute
              element={<Layout ChildComponent={Bookingrecord} />}
            />
          }
        />
        <Route
          path="/reservation/:id"
          element={
            <ProtectedRoute element={<Layout ChildComponent={Reservation} />} />
          }
        />

        {/* Rooms Route */}
        <Route
          path="/rooms"
          element={
            <ProtectedRoute
              element={<Layout ChildComponent={ViewAllRooms} />}
            />
          }
        />
        <Route
          path="/add-rooms"
          element={
            <ProtectedRoute element={<Layout ChildComponent={AddRooms} />} />
          }
        />
        <Route
          path="/edit-rooms/:id"
          element={
            <ProtectedRoute element={<Layout ChildComponent={EditRooms} />} />
          }
        />

        {/* Service Route */}
        <Route
          path="/add-service"
          element={
            <ProtectedRoute element={<Layout ChildComponent={AddService} />} />
          }
        />
        <Route
          path="/edit-service/:id"
          element={
            <ProtectedRoute element={<Layout ChildComponent={EditService} />} />
          }
        />
        <Route
          path="/room-service"
          element={
            <ProtectedRoute
              element={<Layout ChildComponent={ViewRoomService} />}
            />
          }
        />

        {/* Item Route */}
        <Route
          path="/Item"
          element={
            <ProtectedRoute element={<Layout ChildComponent={Food} />} />
          }
        />

        {/* Category Route */}
        {/* <Route
          path="/category"
          element={
            <ProtectedRoute element={<Layout ChildComponent={Category} />} />
          }
        /> */}

        {/* Billing Route */}
        <Route
          path="/billing"
          element={
            <ProtectedRoute
              element={<Layout ChildComponent={BillingTabPanel} />}
            />
          }
        />
        <Route
          path="/room-invoice/:id"
          element={
            <ProtectedRoute element={<Layout ChildComponent={RoomInvoice} />} />
          }
        />
        <Route
          path="/order-invoice/:id"
          element={
            <ProtectedRoute
              element={<Layout ChildComponent={OrderInvoice} />}
            />
          }
        />
        <Route
          path="/both-invoice/:id"
          element={
            <ProtectedRoute element={<Layout ChildComponent={BothInvoice} />} />
          }
        />

        {/* Reports Route */}
        <Route
          path="/reports"
          element={
            <ProtectedRoute element={<Layout ChildComponent={Reports} />} />
          }
        />

        {/* Expense Route */}
        <Route
          path="/expense"
          element={
            <ProtectedRoute element={<Layout ChildComponent={Expense} />} />
          }
        />

        {/* Company Route */}
        <Route
          path="/company"
          element={
            <ProtectedRoute element={<Layout ChildComponent={Company} />} />
          }
        />

        {/* Company Route */}
        <Route
          path="/logs"
          element={
            <ProtectedRoute element={<Layout ChildComponent={LogPage} />} />
          }
        />

        {/* Company Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Box>
  );
};

export default App;
