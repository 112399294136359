import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ErrorAlert, SuccessAlert } from "../../../Model/Alert/SuccessAlert";
const Base_url = process.env.REACT_APP_API_URL + "/booking";
const Edit_Base_url = process.env.REACT_APP_API_URL + "/booking/edit";
const Cancel_Base_url = process.env.REACT_APP_API_URL + "/booking/cancel";

//%%%%%%%%%%%%%%%%%%%%%%%%%%%%% POST ROOM--SERVICE API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const PostBookingAPI = createAsyncThunk(
  "PostBookingAPI",
  async ({ formdata, Token, id }) => {
    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.post(`${Base_url}/${id}`, formdata, {
        headers,
      });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('error', error);
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

export const PutBookingAPI = createAsyncThunk(
  "PutBookingAPI",
  async ({ formdata, Token, id }) => {
    const headers = {
     "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.put(`${Edit_Base_url}/${id}`, formdata, {
        headers,
      });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('error', error);
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

export const CancelBookApi = createAsyncThunk(
  "CancelBookApi",
  async ({ Token, id }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.put(
        `${Cancel_Base_url}/${id}`,
        {},
        { headers }
      );
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('error', error);
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

// export const GetAllBookingAPI = createAsyncThunk(
//   "GetAllBookingAPI",
//   async ({ Token }) => {
//     const headers = {
//       "Content-type": "application/json",
//       Authorization: `Bearer ${Token}`,
//     };
//     try {
//       const response = await axios.get(`${Base_url}`, { headers });
//       if (response.data.status === false) {
//         ErrorAlert(response.data.message);
//       }
//       return response.data;
//     } catch (error) {
//       console.error('error', error);
//       if (error.response.status === 500) {
//         ErrorAlert(error.response.data.message);
//       }
//       return error.response.data;
//     }
//   }
// );

// export const GetBookingAPI = createAsyncThunk(
//   "GetBookingAPI",
//   async ({ Token, id }) => {
//     const headers = {
//       "Content-type": "application/json",
//       Authorization: `Bearer ${Token}`,
//     };
//     try {
//       const response = await axios.get(
//         `${Base_url}/by-id/${id}`,
//         {},
//         { headers }
//       );
//       if (response.data.status === false) {
//         ErrorAlert(response.data.message);
//       }
//       return response.data;
//     } catch (error) {
//       console.error('error', error);
//       if (error.response.status === 500) {
//         ErrorAlert(error.response.data.message);
//       }
//       return error.response.data;
//     }
//   }
// );

// export const GetBookingByRoomAPI = createAsyncThunk(
//   "GetBookingByRoomAPI",
//   async ({ Token, id }) => {
//     const headers = {
//       "Content-type": "application/json",
//       Authorization: `Bearer ${Token}`,
//     };
//     try {
//       const response = await axios.get(
//         `${Base_url}/by-room/${id}`,
//         {},
//         { headers }
//       );
//       if (response.data.status === false) {
//         ErrorAlert(response.data.message);
//       }
//       return response.data;
//     } catch (error) {
//       console.error('error', error);
//       if (error.response.status === 500) {
//         ErrorAlert(error.response.data.message);
//       }
//       return error.response.data;
//     }
//   }
// );

// export const DeleteBookingAPI = createAsyncThunk(
//   "DeleteBookingAPI",
//   async ({ Token, id }) => {
//     const headers = {
//       "Content-type": "application/json",
//       Authorization: `Bearer ${Token}`,
//     };
//     try {
//       const response = await axios.delete(`${Base_url}/${id}`, {}, { headers });
//       if (response.data.status === true) {
//         SuccessAlert(response.data.message);
//       } else {
//         ErrorAlert(response.data.message);
//       }
//       return response.data;
//     } catch (error) {
//       console.error('error', error);
//       if (error.response.status === 500) {
//         ErrorAlert(error.response.data.message);
//       }
//       return error.response.data;
//     }
//   }
// );

const BookingSlice = createSlice({
  name: "BookingSlice",
  initialState: {
    iserror: null,
    message: "",
    status: null,
    isLoading: false,
    BookingData: [],
  },

  extraReducers: (builder) => {
    //%%%%%%%%%%%%%%%%%% POST API HANDLE  %%%%%%%%%%%%%%%%%%%
    builder.addCase(PostBookingAPI.rejected, (state, action) => {
      state.isLoading = true;
      state.iserror = "Api Cancelled";
    });
    builder.addCase(PostBookingAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PostBookingAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%% Cancel API HANDLE  %%%%%%%%%%%%%%%%%%%
    builder.addCase(CancelBookApi.rejected, (state, action) => {
      state.isLoading = true;
      state.iserror = "Api Cancelled";
    });
    builder.addCase(CancelBookApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(CancelBookApi.fulfilled, (state, action) => {
      // console.error("Actionsnjsjjs%%%%%%%%%%%%%%%",action.payload)
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%% PuT API HANDLE  %%%%%%%%%%%%%%%%%%%
    builder.addCase(PutBookingAPI.rejected, (state, action) => {
      state.isLoading = true;
      state.iserror = "Api Cancelled";
    });
    builder.addCase(PutBookingAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PutBookingAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    // //%%%%%%%%%%%%%%%%%% GET ALL BOOKING API HANDLE  %%%%%%%%%%%%%%%%%%%
    // builder.addCase(GetAllBookingAPI.rejected, (state, action) => {
    //   state.isLoading = true;
    //   state.iserror = "Api Cancelled";
    // });
    // builder.addCase(GetAllBookingAPI.pending, (state, action) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(GetAllBookingAPI.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.message = action.payload.message;
    //   state.status = action.payload.status;
    //   state.BookingData = action.payload.status ? action.payload.result : [];
    // });
    // //%%%%%%%%%%%%%%%%%% GET BOOKING BY ID API HANDLE  %%%%%%%%%%%%%%%%%%%
    // builder.addCase(GetBookingAPI.rejected, (state, action) => {
    //   state.isLoading = true;
    //   state.iserror = "Api Cancelled";
    // });
    // builder.addCase(GetBookingAPI.pending, (state, action) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(GetBookingAPI.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.message = action.payload.message;
    //   state.status = action.payload.status;
    //   state.BookingData = action.payload.status ? action.payload.result : [];
    // });
    // //%%%%%%%%%%%%%%%%%% GET ALL BOOKING BY ROOM ID API HANDLE  %%%%%%%%%%%%%%%%%%%
    // builder.addCase(GetBookingByRoomAPI.rejected, (state, action) => {
    //   state.isLoading = true;
    //   state.iserror = "Api Cancelled";
    // });
    // builder.addCase(GetBookingByRoomAPI.pending, (state, action) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(GetBookingByRoomAPI.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.message = action.payload.message;
    //   state.status = action.payload.status;
    //   state.BookingData = action.payload.status ? action.payload.result : [];
    // });

    // //%%%%%%%%%%%%%%%%%% DELETE BOOKING API HANDLE  %%%%%%%%%%%%%%%%%%%
    // builder.addCase(DeleteBookingAPI.rejected, (state, action) => {
    //   state.isLoading = true;
    //   state.iserror = "Api Cancelled";
    // });
    // builder.addCase(DeleteBookingAPI.pending, (state, action) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(DeleteBookingAPI.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.BookingData = action.payload.result;
    //   state.message = action.payload.message;
    //   state.status = action.payload.status;
    // });
  },
});

export default BookingSlice.reducer;