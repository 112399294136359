import { DataGrid } from "@mui/x-data-grid";

const DataTable = ({ columns, rows, onRowClick }) => {
  const visibleColumns = columns.filter((col) => col.visible);

  return (
    <DataGrid
      columns={visibleColumns}
      rows={rows}
      autoHeight
      onRowClick={onRowClick}
      disableColumnMenu
      onMenuClose={() => {}}
      getRowId={(row) => row.id}
      disableSelectionOnClick
      getRowClassName={(params) => `custom-row ${params.row}`}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 8,
          },
        },
      }}
      pageSizeOptions={[3]}
      style={{
        fontSize: "16px",
        // border: "none",
      }}
      sx={{
        ".MuiDataGrid-columnHeader": {
          backgroundColor: "#0288d1",
          color: "white",
          border:"10px solid",
          borderRadius:"15px"
        },
        ".css-qvtrhg-MuiDataGrid-virtualScroller": {
          "&::-webkit-scrollbar": {
            width: "15px",
          },
        },
        ".MuiDataGrid-cell": {
          paddingleft: "0px ",
        },
        ".custom-row": {
          cursor: "pointer", // Set cursor to pointer for all rows
        },
      }}
    />
  );
};

export default DataTable;
