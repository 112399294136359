import MiniDrawer from "./Components/Admin/Include/Drawer";

const Layout = ({ ChildComponent }) => {
  return (
    <>
      <MiniDrawer ChildComponent={<ChildComponent />} />
    </>
  );
};

export default Layout;
