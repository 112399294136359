import React, { useMemo, useState } from "react";
import {
  Box,
  Tooltip,
  Button,
  Typography,
  IconButton,
  Paper,
} from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CreditScoreTwoToneIcon from "@mui/icons-material/CreditScoreTwoTone";
import dayjs from "dayjs";
import { NavLink } from "react-router-dom";
import DataTable from "../../../DataTable";
import { OrderInvoicePayment_Status } from "../../../../store/Slice/BillingSlice/BillingSlice";
import { GetAllBookingRecord } from "../../../../store/Slice/BookingRecordSlice/BookingRecordSlice";
import { MySwal } from "../../../../Model/Alert/SuccessAlert";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

const BookingRoomServiceDetail = ({ data, sethandlegridrow }) => {
    const Token = Cookies.get("Token");
    const dispatch = useDispatch();

  const handleclose = () => {
    sethandlegridrow((previos) => !previos);
  };

  const datawithSerialNumber = useMemo(
    () =>
      data.map((item, index) => ({
        ...item,
        serialNumber: index + 1,
      })),
    [data]
  );

  const [columns, setColumns] = useState([
    {
      field: "serialNumber",
      headerName: "Sr. No.",
      width: 0,
      headerAlign: "center",
      align: "center",
      visible: true,
    },
    {
      field: "item_name",
      headerName: "Name",
      width: 100,
      headerAlign: "center",
      align: "center",
      visible: true,
    },
    {
      field: "price",
      headerName: "Price",
      width: 100,
      headerAlign: "center",
      align: "center",
      visible: true,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 100,
      headerAlign: "center",
      align: "center",
      visible: true,
    },
    {
      field: "total",
      headerName: "Total",
      width: 100,
      headerAlign: "center",
      align: "center",
      visible: true,
    },
  ]);

  return (
    <Box
      sx={{
        padding: "5px",
        height: "440px",
        overflow: "auto",
        "::-webkit-scrollbar": {
          width: "0px",
        },
      }}
    >
      <Box
        sx={{ width: "100%", mb: 2 }}
        display={"flex"}
        justifyContent={"end"}
      >
        <Tooltip title="Close">
          <Button variant="contained" color="error" onClick={handleclose}>
            Close
          </Button>
        </Tooltip>
      </Box>
      {/* {roomServiceData} */}
      <Paper sx={{ marginTop: "22px" }}>
        <DataTable columns={columns} rows={datawithSerialNumber} />
      </Paper>
    </Box>
  );
};

export default BookingRoomServiceDetail;
