import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const checkIN_Base_URL = process.env.REACT_APP_API_URL + "/dashboard";
// const recent_Booking_URl = process.env.REACT_APP_API_URL + "/recent/booking"
// const recent_order_url = process.env.REACT_APP_API_URL + "/recent/orders"
// const AvailabeBase_url = process.env.REACT_APP_API_URL + "/available/rooms"
// const Specific_Base_URL=process.env.REACT_APP_API_URL + "/get/speceficroom"

// const TotalRoom_URL=process.env.REACT_APP_API_URL + "/totalrooms"
// const TotalbookRoom_URL=process.env.REACT_APP_API_URL + "/bookedrooms"
// const Today_URL=process.env.REACT_APP_API_URL + "/todayorder"

// //%%%%%%%%%%%%%%%%%%%%%%%%%%%% POST ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// export const AddRoomApi = createAsyncThunk("AddRoomApi", async ({ values, Token }) => {
//     const headers = { 'Content-type': "application/json", 'Authorization': `Bearer ${Token}` }
//     try {
//         const response = await axios.post(AddBase_url, values, { headers })
//         return response.data

//     } catch (error) {
// console.error('error', error)
//         return error
//     }
// })

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% GET ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// export const RecentBookingApi = createAsyncThunk("RecentBookingApi", async ({ Token }) => {
//     const headers = { 'Content-type': "application/json", 'Authorization': `Bearer ${Token}` }
//     try {
//         const response = await axios.get(recent_Booking_URl, { headers })
//         return response.data

//     } catch (error) {
//         console.error('error', error)
//         return error
//     }
// })

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% Booked ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const DasboardsDetails = createAsyncThunk(
  "DasboardsDetails",
  async ({ Token }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.get(checkIN_Base_URL, { headers });
      return response.data;
    } catch (error) {
      console.error("error", error);
      return error;
    }
  }
);

// //%%%%%%%%%%%%%%%%%%%%%%%%%%%% Available ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// export const RecentOrderApi = createAsyncThunk("RecentOrderApi", async ({ Token }) => {
//     const headers = { 'Content-type': "application/json", 'Authorization': `Bearer ${Token}` }
//     try {
//         const response = await axios.get(recent_order_url, { headers })
//         return response.data

//     } catch (error) {
//         console.error('error', error)
//         return error
//     }
// })

// //%%%%%%%%%%%%%%%%%%%%%%%%%%%% Cancel ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// export const TransactionApi = createAsyncThunk("TransactionApi", async ({ Token }) => {
//     const headers = { 'Content-type': "application/json", 'Authorization': `Bearer ${Token}` }
//     try {
//         const response = await axios.get(checkIN_Base_URL, { headers })
//         return response.data

//     } catch (error) {
//         console.error('error', error)
//         return error
//     }
// })

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% GET ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// export const GetSpecificeRoomApi = createAsyncThunk("GetSpecificeRoomApi", async ({ Token,id }) => {
//     const headers = { 'Content-type': "multipart/form-data", 'Authorization': `Bearer ${Token}` }
//     try {
//         const response = await axios.get(`${Specific_Base_URL}/${id}`, { headers })
//         return response.data

//     } catch (error) {
// console.error('error', error)
//         return { message: 'Room not found', result: {} };
//         return error
//     }
// })

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% PUT ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// export const PutRoomApi = createAsyncThunk("PutRoomApi", async ({ Token, values, id }) => {
//     const headers = { 'Content-type': "multipart/form-data", 'Authorization': `Bearer ${Token}` }
//     try {
//         const response = await axios.put(`${Base_url}/${id}`, values, { headers })
//         return response.data

//     } catch (error) {
// console.error('error', error)
//         return error
//     }
// })

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% DELETE ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// export const DeleteRoomApi = createAsyncThunk("DeleteRoomApi", async ({ Token, values, id }) => {
//     const headers = { 'Content-type': "multipart/form-data", 'Authorization': `Bearer ${Token}` }
//     try {
//         const response = await axios.delete(`${Base_url}/${id}`, { headers })
//         return response.data

//     } catch (error) {
// console.error('error', error)
//         return error
//     }
// })

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% GET ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// export const TotalRoomsApi = createAsyncThunk("TotalRoomsApi", async ({ Token }) => {
//     const headers = { 'Content-type': "application/json", 'Authorization': `Bearer ${Token}` }
//     try {
//         const response = await axios.get(TotalRoom_URL, { headers })
//         return response.data

//     } catch (error) {
//         console.error('error', error)
//         return error
//     }
// })

// //%%%%%%%%%%%%%%%%%%%%%%%%%%%% Booked ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// export const TotalBookRoomAPI = createAsyncThunk("TotalBookRoomAPI", async ({ Token }) => {
//     const headers = { 'Content-type': "application/json", 'Authorization': `Bearer ${Token}` }
//     try {
//         const response = await axios.get(TotalbookRoom_URL, { headers })
//         return response.data

//     } catch (error) {
//         console.error('error', error)
//         return error
//     }
// })

// //%%%%%%%%%%%%%%%%%%%%%%%%%%%% Available ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// export const TodayOrderAPI = createAsyncThunk("TodayOrderAPI", async ({ Token }) => {
//     const headers = { 'Content-type': "application/json", 'Authorization': `Bearer ${Token}` }
//     try {
//         const response = await axios.get(Today_URL, { headers })
//         return response.data

//     } catch (error) {
//         console.error('error', error)
//         return error
//     }
// })

// //%%%%%%%%%%%%%%%%%%%%%%%%%%%% Cancel ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// export const TotalIncomeApi = createAsyncThunk("TotalIncomeApi", async ({ Token }) => {
//     const headers = { 'Content-type': "application/json", 'Authorization': `Bearer ${Token}` }
//     try {
//         const response = await axios.get(checkIN_Base_URL, { headers })
//         return response.data

//     } catch (error) {
//         console.error('error', error)
//         return error
//     }
// })

const DashboardSlice = createSlice({
  name: "DashboardSlice",
  initialState: {
    iserror: null,
    isLoading: false,
    message: "",
    CardDetails: {},
    RecentBookings: [],
    RecentCheckIns: [],
    RecentOrders: [],
    RoomSales: {},
    Visitors: [],
  },
  extraReducers: (builder) => {
    //%%%%%%%%%%%%%%%%%%%%%%% GET Booked API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(DasboardsDetails.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(DasboardsDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(DasboardsDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;

      state.CardDetails = action.payload.status
        ? action.payload.cardDetails
        : {};
      state.RecentBookings = action.payload.status
        ? action.payload.recentBookings
        : [];
      state.RecentCheckIns = action.payload.status
        ? action.payload.recentCheckIns
        : [];
      state.RecentOrders = action.payload.status
        ? action.payload.recentOrders
        : [];
      state.RoomSales = action.payload.status ? action.payload.roomSales : {};
      state.Visitors = action.payload.status ? action.payload.visitors : [];
    });
  },
});
export default DashboardSlice.reducer;
