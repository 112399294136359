import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const Base_url = process.env.REACT_APP_API_URL + "/update/privacy";
const Get_url = process.env.REACT_APP_API_URL + "/get/privacy";

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% POST CATEGORY API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const PrivacyApi = createAsyncThunk(
  "PrivacyApi",
  async ({ Token, privacy }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.put(Base_url, privacy, { headers });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);


export const GetPrivacyApi = createAsyncThunk(
  "GetPrivacyApi",
  async ({ Token }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.get(Get_url, { headers });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const DrawerSlice = createSlice({
  name: "DrawerOpenAndClose",
  initialState: {
    privacyOpen: null,
    status: null,
    message: null,
  },
  extraReducers: (builder) => {
    //%%%%%%%%%%%%%%%%%%%%%%% POST API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(PrivacyApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PrivacyApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PrivacyApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
      if (action.payload.status === true) {
        state.privacyOpen = action.payload.privacy;
      }
    });
    
    //%%%%%%%%%%%%%%%%%%%%%%% GET API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(GetPrivacyApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetPrivacyApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetPrivacyApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
      if (action.payload.status === true) {
        state.privacyOpen = action.payload.privacy;
      }
    });
  },
});

export default DrawerSlice.reducer;
