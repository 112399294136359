import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const Booked_Report_URl = process.env.REACT_APP_API_URL + "/bookedrooms/report"
const Cancel_URL = process.env.REACT_APP_API_URL + "/cancelrooms/report"
const FoodURL = process.env.REACT_APP_API_URL + "/foodorder/report"
const UserURL = process.env.REACT_APP_API_URL + "/user/report"
const Booked_Report_URL_POS=process.env.REACT_APP_API_URL + "/booked/room/report"
const Cancel_Report_URL_POSt=process.env.REACT_APP_API_URL + "/cancel/room/report"
const FOOD_Report_URL_POSt=process.env.REACT_APP_API_URL + "/food/order/report"
const User_Report_URL_POSt=process.env.REACT_APP_API_URL + "/userreport"
const Guest_Report_URL_POSt=process.env.REACT_APP_API_URL + "/get/guest/history"



//%%%%%%%%%% POST USER API %%%%%%%%%%%%
// export const PostGuestData = createAsyncThunk("PostGuestData", async ({ Token, data }) => {
//     const headers = {'Content-type': "multipart/form-data",'Authorization': `Bearer ${Token}`}

//     try {
//         const response = await axios.post(Booked_Report_URl,data,{headers })
//         return response.data

//     } catch (error) {
//         console.error('error', error)
//         return error
//     }
// })

//%%%%%%%%%% GET USER API %%%%%%%%%%%%
export const GetAllBookedRoomReport = createAsyncThunk("GetAllBookedRoomReport", async ({ Token }) => {
    const headers = {
        'Content-type': "multipart/form-data",
        'Authorization': `Bearer ${Token}`
    }

    try {
        const response = await axios.get(Booked_Report_URl,  { headers })
        return response.data

    } catch (error) {
        console.error('error', error)
        return error
    }
})



//%%%%%%%%%% GET All Room Cancel  API %%%%%%%%%%%%
export const GetAllRoomCancelReport = createAsyncThunk("GetAllRoomCancelReport", async ({ Token }) => {
    const headers = {
        'Content-type': "multipart/form-data",
        'Authorization': `Bearer ${Token}`
    }

    try {
        const response = await axios.get(Cancel_URL,  { headers })
        return response.data

    } catch (error) {
        console.error('error', error)
        return error
    }
})

//%%%%%%%%%% GET USER API %%%%%%%%%%%%
export const GetAllUserReport = createAsyncThunk("GetAllUserReport", async ({ Token }) => {
    const headers = {
        'Content-type': "multipart/form-data",
        'Authorization': `Bearer ${Token}`
    }

    try {
        const response = await axios.get(UserURL,  { headers })
        return response.data

    } catch (error) {
        console.error('error', error)
        return error
    }
})



//%%%%%%%%%% GET All Room Cancel  API %%%%%%%%%%%%
export const GetAllFoodReport = createAsyncThunk("GetAllFoodReport", async ({ Token }) => {
    const headers = {
        'Content-type': "multipart/form-data",
        'Authorization': `Bearer ${Token}`
    }

    try {
        const response = await axios.get(FoodURL,  { headers })
        // console.error("respioes",response)
        return response.data

    } catch (error) {
        console.error('error', error)
        return error
    }
})



export const PostBookedRoomDateAPI = createAsyncThunk("PostBookedRoomDateAPI", async ({ Token, values }) => {
    const headers = {'Content-Type': "application/json",'Authorization': `Bearer ${Token}`}

    try {
        const response = await axios.post(Booked_Report_URL_POS,values,{headers })
        return response.data

    } catch (error) {
        console.error('error', error)
        return error
    }
})



export const PostCancelRoomDateAPI = createAsyncThunk("PostCancelRoomDateAPI", async ({ Token, values }) => {
    const headers = {'Content-Type': "application/json",'Authorization': `Bearer ${Token}`}

    try {
        const response = await axios.post(Cancel_Report_URL_POSt,values,{headers })
        return response.data

    } catch (error) {
        console.error('error', error)
        return error
    }
})


export const PostFoodOrderReport = createAsyncThunk("PostFoodOrderReport", async ({ Token, values }) => {
    const headers = {'Content-Type': "application/json",'Authorization': `Bearer ${Token}`}

    try {
        const response = await axios.post(FOOD_Report_URL_POSt,values,{headers })
        return response.data

    } catch (error) {
        console.error('error', error)
        return error
    }
})


// export const PostUserReport = createAsyncThunk("PostUserReport", async ({ Token, values }) => {
//     const headers = {'Content-Type': "application/json",'Authorization': `Bearer ${Token}`}

//     try {
//         const response = await axios.post(User_Report_URL_POSt,values,{headers })
//         return response.data

//     } catch (error) {
//         console.error('error', error)
//         return error
//     }
// })

export const PostUserReportWithHistory = createAsyncThunk("PostUserReportWithHistory", async ({ Token, values }) => {
    const headers = {'Content-Type': "application/json",'Authorization': `Bearer ${Token}`}

    try {
        const response = await axios.post(Guest_Report_URL_POSt,values,{headers })
        return response.data

    } catch (error) {
        console.error('error', error)
        return error
    }
})



const ReportSlice = createSlice({
    name: "ReportSlice",
    initialState: {
        BookedRoomReport: [],
        CancelRoomReport: [],
        FoodReport: [],
        UserReport: [],
        BookedFilterData: [],
        iserror: null,
        isLoading: null,
        message:""
    },

    extraReducers: (builder) => {

        //%%%%%%%%%%%%%%%%%%% Post BOOKED ROOM API HANDLE %%%%%%%%%%%%%%%%%%%%%
        builder.addCase(PostBookedRoomDateAPI.rejected, (state, action) => {
            state.iserror = "Client side Error";
            state.message = action.payload.message;
        });
        builder.addCase(PostBookedRoomDateAPI.pending, (state, action) => {
            state.isLoading = true;

        });
        builder.addCase(PostBookedRoomDateAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.message = action.payload.message;
            if(action.payload.status){
                state.BookedRoomReport=action.payload.result

            }else{
                state.BookedRoomReport= []
            }
            // state.GuestDatatableData = action.payload.result;
        });


         //%%%%%%%%%%%%%%%%%%% Post Cancel ROOM API HANDLE %%%%%%%%%%%%%%%%%%%%%
         builder.addCase(PostCancelRoomDateAPI.rejected, (state, action) => {
            state.iserror = "Client side Error";
            state.message = action.payload.message;
        });
        builder.addCase(PostCancelRoomDateAPI.pending, (state, action) => {
            state.isLoading = true;

        });
        builder.addCase(PostCancelRoomDateAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.message = action.payload.message;
            if(action.payload.status){
                state.BookedRoomReport=action.payload.result

            }else{
                state.BookedRoomReport= []
            }
            // state.GuestDatatableData = action.payload.result;
        });

          //%%%%%%%%%%%%%%%%%%% Post User Report API HANDLE %%%%%%%%%%%%%%%%%%%%%
        //   builder.addCase(PostUserReport.rejected, (state, action) => {
        //     state.iserror = "Client side Error";
        //     state.message = action.payload.message;
        // });
        // builder.addCase(PostUserReport.pending, (state, action) => {
        //     state.isLoading = true;

        // });
        // builder.addCase(PostUserReport.fulfilled, (state, action) => {
        //     state.isLoading = false;
        //     state.message = action.payload.message;
        //     if(action.payload.status){
        //         state.BookedRoomReport=action.payload.result

        //     }else{
        //         state.BookedRoomReport= []
        //     }
        //     // state.GuestDatatableData = action.payload.result;
        // });



           //%%%%%%%%%%%%%%%%%%% Post FOOD API HANDLE %%%%%%%%%%%%%%%%%%%%%
           builder.addCase(PostFoodOrderReport.rejected, (state, action) => {
            state.iserror = "Client side Error";
            state.message = action.payload.message;
        });
        builder.addCase(PostFoodOrderReport.pending, (state, action) => {
            state.isLoading = true;

        });
        builder.addCase(PostFoodOrderReport.fulfilled, (state, action) => {
            state.isLoading = false;
            state.message = action.payload.message;
            if(action.payload.status){
                state.BookedRoomReport=action.payload.result

            }else{
                state.BookedRoomReport= []
            }
            // state.GuestDatatableData = action.payload.result;
        });


        // //%%%%%%%%%%%%%%%%%%% VIEW API HANDLE %%%%%%%%%%%%%%%%%%%%%
        builder.addCase(GetAllBookedRoomReport.rejected, (state, action) => {
            state.iserror = "Client side Error";

        });
        builder.addCase(GetAllBookedRoomReport.pending, (state, action) => {
            state.isLoading = true;

        });
        builder.addCase(GetAllBookedRoomReport.fulfilled, (state, action) => {
            state.isLoading = false;
            state.BookedRoomReport = action.payload.status?action.payload.result:[];
        });



        
        // //%%%%%%%%%%%%%%%%%%% VIEW API HANDLE %%%%%%%%%%%%%%%%%%%%%
        builder.addCase(GetAllRoomCancelReport.rejected, (state, action) => {
            state.iserror = "Client side Error";

        });
        builder.addCase(GetAllRoomCancelReport.pending, (state, action) => {
            state.isLoading = true;

        });
        builder.addCase(GetAllRoomCancelReport.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CancelRoomReport = action.payload.status?action.payload.result:[];
        });



        
        // //%%%%%%%%%%%%%%%%%%% VIEW API HANDLE %%%%%%%%%%%%%%%%%%%%%
        builder.addCase(GetAllUserReport.rejected, (state, action) => {
            state.iserror = "Client side Error";

        });
        builder.addCase(GetAllUserReport.pending, (state, action) => {
            state.isLoading = true;

        });
        builder.addCase(GetAllUserReport.fulfilled, (state, action) => {
            state.isLoading = false;
            state.UserReport = action.payload.status?action.payload.result:[];
        });

        // //%%%%%%%%%%%%%%%%%%% VIEW API HANDLE %%%%%%%%%%%%%%%%%%%%%
        builder.addCase(PostUserReportWithHistory.rejected, (state, action) => {
            state.iserror = "Client side Error";

        });
        builder.addCase(PostUserReportWithHistory.pending, (state, action) => {
            state.isLoading = true;

        });
        builder.addCase(PostUserReportWithHistory.fulfilled, (state, action) => {
            state.isLoading = false;
            state.UserReport = action.payload.status?action.payload.result:[];
        });



        
        // //%%%%%%%%%%%%%%%%%%% VIEW API HANDLE %%%%%%%%%%%%%%%%%%%%%
        builder.addCase(GetAllFoodReport.rejected, (state, action) => {
            state.iserror = "Client side Error";

        });
        builder.addCase(GetAllFoodReport.pending, (state, action) => {
            state.isLoading = true;

        });
        builder.addCase(GetAllFoodReport.fulfilled, (state, action) => {
            // console.error("action",action.payload.result)
            state.isLoading = false;
            state.FoodReport = action.payload.result;
            state.UserReport = action.payload.status?action.payload.result:[];
        });


    }

})

export default ReportSlice.reducer
