import { configureStore } from "@reduxjs/toolkit";
import GuestDataTableSlice from "./Slice/GuestSlice/GuestDataTableSlice";
import AdminSlice from "./Slice/AdminProfileSlice/AdminSlice";
import FoodSlice from "./Slice/FoodSlice/FoodSlice";
import RoomSlice from "./Slice/RoomsSlice/RoomSlice";
import RoomServiceSlice from "./Slice/RoomServiceSlice/RoomServiceSlice";
import AuthSlice from "./Slice/AuthSlice/AuthSlice";
import BookingSlice from "./Slice/Booking/BookingSlice";
import DashboardSlice from "./Slice/DashBoardSlice.js/DashboardSlice";
import ReportSlice from "./Slice/ReportSlice/ReportSlice";
import BillingSlice from "./Slice/BillingSlice/BillingSlice";
import FoodCategorySlice from "./Slice/FoodCategorySlice/FoodCategorySlice";
// import ReservationSlice from "./Slice/ReservationSlice/ReservationSlice";
import ReservationSlice from "./Slice/ReservationSlice/ReservationSlice";
// import BookingRecord fro./Slice/BookingRecordSlice/BookingRecordSliceord";
import BookingRecordSlice from "./Slice/BookingRecordSlice/BookingRecordSlice";
import ExpenseSlice from "./Slice/ExpenseSlice/ExpenseSlice";
import CompanySlice from "./Slice/CompanySlice/CompanySlice";
import LogPageSlice from "./Slice/LogPageSlice/LogPageSlice";
import DrawerSlice from "./Slice/DrawerSlice/DrawerSlice";

const store = configureStore({
  reducer: {
    UserStore: GuestDataTableSlice,
    AdminStore: AdminSlice,
    FoodStore: FoodSlice,
    FoodCategoryStore: FoodCategorySlice,
    RoomStore: RoomSlice,
    RoomServiceStore: RoomServiceSlice,
    AuthStore: AuthSlice,
    BookingStore: BookingSlice,
    DashBoardStore: DashboardSlice,
    ReportStore: ReportSlice,
    BillingStore: BillingSlice,
    ReservationStore: ReservationSlice,
    BookingrecordStore: BookingRecordSlice,
    ExpenseStore: ExpenseSlice,
    CompanyStore: CompanySlice,
    LogPageStore: LogPageSlice,
    DrawerStore: DrawerSlice,
  },
});
export default store;
