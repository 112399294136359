import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const MySwal = withReactContent(Swal);

export const SuccessAlert = (message) => {
  MySwal.fire({
    position: "center",
    icon: "success",
    title: message,
    showConfirmButton: false,
    timer: 1800,
  });
};

export const ErrorAlert = (message) => {
  MySwal.fire({
    icon: "warning",
    title: "Oops!!",
    confirmButtonText: "OK",
    confirmButtonColor: "#28a745",
    text: message,
  });
};