import {
  Backdrop,
  Button,
  IconButton,
  Stack,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import EyeImg from "../../../Assets/img/eye.png";
import FileDownloadTwoToneIcon from "@mui/icons-material/FileDownloadTwoTone";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  privacyActions,
  PrivacyApi,
} from "../../../store/Slice/DrawerSlice/DrawerSlice";
import { useDispatch, useSelector } from "react-redux";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Cookies from "js-cookie";
import { MySwal } from "../../../Model/Alert/SuccessAlert";

const PrivacyBackDrop = ({ privacyOpen }) => {
  const Token = Cookies.get("Token");
  const id = Cookies.get("userid");
  const dispatch = useDispatch();
  const handleImageClose = () => {
    MySwal.fire({
      title: "Enter pincode to unlock",
      input: "password",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !MySwal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        if (+result.value === 9457) {
          const privacy = false;
          dispatch(PrivacyApi({ Token, privacy: { privacy } }));
          MySwal.fire({
            position: "center",
            icon: "success",
            title: `Unlocked`,
            showConfirmButton: false,
            timer: 900,
          });
        } else {
          MySwal.fire({
            position: "center",
            icon: "info",
            timer: 900,
            title: `Incorrect Password`,
            showConfirmButton: false,
          });
        }
      }
    });
  };
  const isMobile = useMediaQuery("(min-width:900px)");
  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          // zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 2,
          backdropFilter: "blur(20px)", // Add this line for the blur effect
        }}
        open={privacyOpen}
      >
        <Stack direction="column" width="100%" height="100%">
          <Stack
            direction="row"
            width="100%"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Tooltip title="Close" arrow>
              <IconButton onClick={handleImageClose}>
                <CloseTwoToneIcon
                  sx={{
                    color: "#fff",
                    fontSize: "2rem",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
          <Stack
            direction="column"
            width="100%"
            height="100%"
            gap={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding={isMobile ? "5rem" : "2rem"}
          >
            <LazyLoadImage
              effect="blur"
              loading="lazy"
              alt="demo profile pic"
              src={EyeImg}
              style={{
                width: "50vw",
                height: "50vh",
                objectFit: "contain",
              }}
            />

            <Button onClick={handleImageClose} color="info" variant="contained">
              <LockOpenIcon
                sx={{
                  color: "#fff",
                  fontSize: "2rem",
                }}
              />
              UNLOCK
            </Button>
          </Stack>
        </Stack>
      </Backdrop>
    </>
  );
};

export default PrivacyBackDrop;
